import React from "react"
import { Link } from "gatsby"
import course1 from "../assets/images/course-1-1.jpg"
import team1 from "../assets/images/team-1-1.jpg"
import Img from "gatsby-image"

function SearchList({ resultados }) {
  const baseGameUrl = "https://localhost:8000/juegos/"
  const baseArticleUrl = "https://localhost:8000"
  return (
    <section className="course-one course-page">
      <div className="container">
        <div className="row">
          {resultados.map(elemento => (
              <div key={elemento.id} className="col-lg-4">
                <div className="course-one__single">
                  <div className="course-one__image">
                    {elemento.image ?
                      (
                        <Img fluid={{ ...elemento.image.sharp.fluid, aspectRatio: 11 / 9 }} alt={elemento.name} />
                      )
                      :
                      (<img src={"https://rolteca.com/covers/" + elemento.uuid + "..jpg"} alt={elemento.name} />)
                    }
                  </div>

                  {elemento.type === "article" ?
                    (
                      <div className="course-one__content">
                        <a href="#none" className="course-one__category color-2">
                          Articulo </a>
                        <h2 className="course-one__title">
                          <Link to={ elemento.slug}> {elemento.name} </Link>
                        </h2>
                        <Link to={elemento.slug} className="course-one__link"> Ver </Link>
                      </div>)
                    :
                    (<div className="course-one__content"><a href="#none" className="course-one__category color-1">
                      Juego </a>
                      <h2 className="course-one__title">
                        <Link to={"/juegos/" + elemento.slug}> {elemento.name} </Link>
                      </h2>
                      <Link to={"/juegos/" + elemento.slug} className="course-one__link"> Ver </Link>
                    </div>)
                  }


                </div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  )
}

export default SearchList
