import React, { useEffect, useState } from "react"
import Layout from "../components/Layout";
import Nav from "../components/Nav"
import PageHeader from "../components/PageHeader"
import Footer from "../components/Footer"
import Search from "../components/Search"
import SearchList from "../components/SearchList"
import useBusqueda from "../Hooks/userBusqueda"


const SearchPage = (props) => {
  const params = new URLSearchParams(props.location.search);
  let querySearch = params.get('search');

  if(querySearch===null) querySearch = "";

  const allSearchelements = useBusqueda();
  const [search, setSearch] = useState(querySearch);
  const [filtredElements, setFiltredElements] = useState([]);

  useEffect(() => {
    setFiltredElements([]);
    if(search === ""){
      return;
    }
    let elements = allSearchelements.filter(game => game.searchParam.includes(search.toLowerCase()));
    setFiltredElements(elements);
  },[search]);
  return (
    <Layout pageTitle="Rolteca | Búsqueda"
            slug={"/busqueda"}>
      <Nav />
      <PageHeader title="Búsqueda" />
      <Search
        search={search}
        setSearch={setSearch}
        enableSubmit={false}/>
      <SearchList resultados={filtredElements}/>
      <Footer />
    </Layout>
  );
};

export default SearchPage;
