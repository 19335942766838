import { graphql, useStaticQuery } from "gatsby"

const useBusqueda = () => {

  const response = useStaticQuery(
    graphql`
        query searchQuery {
            allStrapiBlogArticle {
                totalCount
                nodes {
                    id
                    image {
                        sharp: childImageSharp {
                            fluid( maxWidth: 370 ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    title
                    slug
                    shortdescription
                }
            }
            allStrapiGames {
                nodes {
                    id
                    uuid
                    name
                    slug
                    image {
                        sharp: childImageSharp {
                            fluid( maxWidth: 370 ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    editions {
                        name
                    }
                    charactersheets {
                        name
                    }
                    helps {
                        name
                    }
                    modules {
                        name
                    }
                }
            }
        }
    `
  )

  let resoponseObject = [];
  for(let i=0; i<response.allStrapiGames.nodes.length; i++){
    let currGame = response.allStrapiGames.nodes[i];
    let searchParam = currGame.name.toLowerCase();

    if(currGame.editions !== null || currGame.editions !== undefined){
      for(let i=0; i<currGame.editions.length; i++){
        searchParam = searchParam + " " + currGame.editions[i].name.toLowerCase();
      }
    }
    if(currGame.charactersheets !== null || currGame.charactersheets !== undefined){
      for(let i=0; i<currGame.charactersheets.length; i++){
        searchParam = searchParam + " " + currGame.charactersheets[i].name.toLowerCase();
      }
    }
    if(currGame.helps !== null || currGame.helps !== undefined){
      for(let i=0; i<currGame.helps.length; i++){
        searchParam = searchParam + " " + currGame.helps[i].name.toLowerCase();
      }
    }
    if(currGame.modules !== null || currGame.modules !== undefined){
      for(let i=0; i<currGame.modules.length; i++){
        searchParam = searchParam + " " + currGame.modules[i].name.toLowerCase();
      }
    }

    resoponseObject.push(
      {
        id: "game"+currGame.id,
        uuid: currGame.uuid,
        name: currGame.name,
        image: currGame.image,
        slug: currGame.slug,
        searchParam: searchParam,
        type: "game"
      });
  }
  for(let i=0; i<response.allStrapiBlogArticle.nodes.length; i++){
    let currArticle = response.allStrapiBlogArticle.nodes[i];
    resoponseObject.push(
      {
        id: "article"+currArticle.id,
        name: currArticle.title,
        image: currArticle.image,
        slug: currArticle.slug,
        searchParam: currArticle.title.toLowerCase(),
        type: "article"
      })
  }
  return resoponseObject;
}

export default useBusqueda;
